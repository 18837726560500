import React from 'react';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import styled from 'styled-components';
import {
  brandColours,
  standardColours,
  sectionMargins,
  fontSize,
  minBreakpointQuery,
  labelStyles,
  fullLabelStyles,
  inputStyles,
  textAreaStyles,
  formButtonStyles,
} from '../styles';
import { Container, Button, Heading } from './ui';

const StyledContactForm = styled.section`
  ${sectionMargins()};
  background-color: ${brandColours.quaternary};

  ${minBreakpointQuery.medium`
    background-color: ${standardColours.white};
  `}
`;

const StyledOuter = styled.div`
  background-color: ${brandColours.quaternary};
  padding: 50px 0;

  ${minBreakpointQuery.medium`
    padding: 80px 100px;
  `}

  ${minBreakpointQuery.mlarge`
    padding: 100px 120px;
  `}

  ${minBreakpointQuery.large`
    padding: 120px 140px;
  `}
`;

const StyledHeading = styled(Heading)`
  text-align: center;
`;

const StyledText = styled.p`
  text-align: center;
  margin-top: 10px;
  line-height: 1.5;
  white-space: break-spaces;

  ${minBreakpointQuery.mlarge`
    ${fontSize(20)};
    margin-top: 15px;
  `}
`;

const StyledInner = styled.form`
  padding-top: 50px;
  display: grid;

  ${minBreakpointQuery.medium`
    grid-template-columns: repeat(2, 1fr);
    column-gap: 40px;
  `}

  ${minBreakpointQuery.mlarge`
    padding-top: 70px;
  `}
`;

const StyledLabel = styled.label`
  ${({ honeypot }) => labelStyles(honeypot)}
`;

const StyledLabelFull = styled.label`
  ${fullLabelStyles()};
`;

const StyledLabelText = styled.span``;

const StyledInput = styled.input`
  ${inputStyles()};
`;

const StyledTextArea = styled.textarea`
  ${textAreaStyles()};
`;

const StyledSubmit = styled(Button)`
  ${formButtonStyles()};
`;

const ContactForm = ({ heading, text }) => (
  <StyledContactForm>
    <Container>
      <StyledOuter>
        <StyledHeading>{heading}</StyledHeading>
        {text && <StyledText>{text}</StyledText>}
        <StyledInner
          id="contact-form"
          name="contact-form"
          method="POST"
          action="/thanks/"
          netlify-honeypot="question"
          data-netlify="true"
          onSubmit={() => {
            trackCustomEvent({
              category: 'Contact Form',
              action: 'Submit',
            });
          }}
        >
          <input type="hidden" name="form-name" value="contact-form" />
          <StyledLabel>
            <StyledLabelText>First Name</StyledLabelText>
            <StyledInput type="text" name="first-name" required />
          </StyledLabel>
          <StyledLabel>
            <StyledLabelText>Last Name</StyledLabelText>
            <StyledInput type="text" name="last-name" required />
          </StyledLabel>
          <StyledLabel>
            <StyledLabelText>Company Name</StyledLabelText>
            <StyledInput type="text" name="company-name" required />
          </StyledLabel>
          <StyledLabel>
            <StyledLabelText>Email Address</StyledLabelText>
            <StyledInput type="email" name="email" required />
          </StyledLabel>
          <StyledLabelFull>
            <StyledLabelText>Message</StyledLabelText>
            <StyledTextArea name="message" required />
          </StyledLabelFull>
          <StyledLabel honeypot={true}>
            <StyledLabelText>Question</StyledLabelText>
            <StyledInput type="text" name="question" />
          </StyledLabel>
          <StyledSubmit type="submit">Submit</StyledSubmit>
        </StyledInner>
      </StyledOuter>
    </Container>
  </StyledContactForm>
);

export default ContactForm;
